<template>
  <b-modal
    id="modal-add-schedule"
    title="Thêm lộ trình"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <basic-select
          label="Chọn loại lộ trình"
          placeholder="--- Chọn loại lộ trình ---"
          required
          changeValueByObject
          :options="scheduleTypes"
          :value.sync="form.type"
          name="type"
          v-validate="'required'"
          :state="validateState('type')"
          :invalidFeedback="errors.first('type')"
          data-vv-as="Loại lộ trình"
          track-by="value"
          value-label="text"
          @update:value="selectType"
        />
        <basic-select
          label="Chọn lộ trình"
          placeholder="--- Chọn lộ trình ---"
          required
          changeValueByObject
          :options="schedules"
          name="schedule"
          v-validate="'required'"
          :state="validateState('schedule')"
          :invalidFeedback="errors.first('schedule')"
          data-vv-as="Lộ trình"
          :value.sync="form.schedule"
          track-by="value"
          value-label="text"
          @update:value="$validator.reset({ name: 'schedule' })"
        />
        <b-form-group
          label="Ngày bắt đầu"
          :id="`startDate-group`"
          label-for="startDate-ID"
          :invalid-feedback="errors.first('startDate')"
          :state="validateState('startDate')"
          class="required"
        >
          <date-picker
            id="startDate-ID"
            placeholder="Chọn gày bắt đầu"
            :disabled-date="disabledBeforeToday"
            v-model="form.startDate"
            :format="dateFomartion"
            input-class="form-control"
            type="date"
            value-type="format"
            name="startDate"
            v-validate="'required'"
            data-vv-as="Ngày bắt đầu"
          />
        </b-form-group>
        <div class="d-flex justify-content-end align-items-center mt-4">
          <b-button class="btn mr-2" type="button" @click="resetModal">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { SCHEDULE_TYPES } from '../../../../core/plugins/constants';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'ModalAddSchedule',
  components: {
    DatePicker,
  },
  data() {
    return {
      scheduleTypes: [
        {
          text: 'Lộ trình vận động',
          value: SCHEDULE_TYPES.MOVEMENT_EXERCISES,
        },
        {
          text: 'hoạt động tự do',
          value: SCHEDULE_TYPES.FREE,
        },
      ],
      schedules: [],
      loading: false,
      form: {
        type: null,
        schedule: null,
        startDate: null,
      },
      dateFomartion: 'DD/MM/YYYY',
    };
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
  },
  methods: {
    resetModal() {
      this.schedules = [];
      this.form = {
        type: null,
        schedule: null,
        startDate: null,
      };
      this.$emit('reset');
    },
    selectType(selected) {
      this.$validator.reset({ name: 'type' });
      if (!selected) return (this.schedules = []);

      this.getScheduleList();
    },
    async getScheduleList() {
      try {
        this.loading = true;
        const { data, meta } = await this.$api.get('/UserDashboard/Agenda', {
          params: {
            classify: this.form.type?.value,
            patientId: this.patientId,
          },
        });

        if (!meta.success) return;

        this.schedules = data;

        if (this.form.type.value === SCHEDULE_TYPES.MOVEMENT_EXERCISES) {
          this.form.schedule = data.find((el) => el.selected);
        }
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    async handleSubmit() {
      const payload = {
        startDate: this.convertDateToTimestamp(this.form.startDate),
        id: this.form.schedule.value,
        patientId: this.patientId,
      };

      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/UserDashboard/Agenda',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.showToastrMessage('Thêm lộ trình thành công', 'success');

        this.$bvModal.hide('modal-add-schedule');
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang=""></style>
